.tease-grid {
  &.tease-post {
    .card-text {
      @extend .text-truncate;
    }

    .post-metas {
      .meta-author {
        font-family: $headings-font-family;
      }
    }
  }

  .card-title {
    font-size: px2rem(14);

    @include media-breakpoint-up(lg) {
      font-size: px2rem(19);
    }

    &,
    a {
      color: $gray-900;
    }
  }
}

@include media-breakpoint-down($desktop-breakpoint) {
  .site-header {
    .offcanvas-body {
      justify-content: space-between !important;
      padding-bottom: 0;
    }
  }

  .nav-main-inner {
    & > .nav-item {
      border-bottom: 1px solid $gray-300;
    }
  }
}

.nav-main-inner {
  .nav-item-title {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

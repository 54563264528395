@import "~font-face-generator/mixin";

$font-file-types: "eot" "woff2" "woff" "ttf" "svg";
$fonts-path: "../fonts";
@include font-face(
  $fonts: (
    "Gotham": (
      100: "GothamThin",
      300: (
        normal: "GothamLight",
        italic: "GothamLight-Italic"
      ),
      normal: (
        normal: "GothamMedium",
        italic: "GothamMedium-Italic"
      ),
      500: (
        normal: "GothamBold",
        italic: "GothamBold-Italic"
      ),
      700: (
        normal: "GothamBlack",
        italic: "GothamBlack-Italic"
      ),
      900: (
        normal: "GothamUltra",
        italic: "GothamUltra-Italic"
      )
    ),
    "Gotham Book": (
      normal: "GothamBook"
    )
  )
) {
  font-display: swap;
}

.font-size-main,
.site-main {
  --wine-main-font-size: .75rem;
  font-size: var(--wine-main-font-size);

  @include media-breakpoint-up(lg) {
    --wine-main-font-size: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  h1, {
    font-size: px2rem(42);
  }

  h2 {
    font-size: px2rem(26);
  }

  h3 {
    font-size: px2rem(20);
  }
}

b,
strong {
  font-family: $headings-font-family;
  font-weight: $font-weight-base;
}

.bolded {
  font-family: $headings-font-family;
  font-weight: $font-weight-light !important;

  b,
  strong {
    font-weight: 500;
  }

  &.stacked {
    b,
    strong {
      display: block;
    }
  }

  &.stacked-mobile {
    @include media-breakpoint-down($desktop-breakpoint) {
      b,
      strong {
        display: block;
      }
    }
  }
}

.bolded-light {
  font-family: $headings-font-family;

  b,
  strong {
    font-weight: 400;
  }
}

.medium-text {
  font-size: .875rem;
  line-height: 1.25;
}

.small-paragraph-mb {
  p {
    margin-bottom: $small-paragraph-margin-bottom;
  }

  address,
  ol,
  ul,
  dl,
  pre,
  figure {
    margin-bottom: $small-paragraph-margin-bottom;
  }

  dd,
  legend {
    margin-bottom: $small-paragraph-margin-bottom / 2;
  }

  p,
  ol,
  ul,
  dl {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

body {
  &.has-download-app-bar {
    padding-bottom: 2.5rem;

    @include media-breakpoint-up($desktop-breakpoint) {
      padding-bottom: unset;
    }
  }

  &.error404 {
    .site-main {
      margin-top: auto;
    }

    .article-body {
      text-align: center;
    }
  }
}

.fixed-bottom {
  z-index: $zindex-fixed - 11;
}

.btn-play {
  --play-svg-width: 4rem;
  display: inline-block;
  transition: all .5s ease;

  @include media-breakpoint-up($desktop-breakpoint) {
    --play-svg-width: 6rem;
  }

  svg-component {
    width: var(--play-svg-width);
    height: auto;
  }

  .circle {
    opacity: 1;
    stroke: $white;
    stroke-dasharray: 650;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 7;
    transition: all .5s ease-in-out;
  }

  .triangle {
    opacity: 1;
    stroke: $white;
    stroke-dasharray: 240;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 7;
    transition: all .7s ease-in-out;
  }

  &:hover {
    .triangle {
      stroke: $white;
      stroke-dashoffset: 480;
      transform: translateY(0);
      animation: nudge .7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }

        30% {
          transform: translateX(-2px);

        }

        50% {
          transform: translateX(5px);
        }

        70% {
          transform: translateX(-5px);
        }

        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      opacity: .3;
      stroke: $white;
      stroke-dashoffset: 650;
    }

  }
}

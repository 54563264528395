.site-footer {
  min-height: 184px;
  padding-top: 184px;
  background: $white url("../images/bg-footer-wave.png") no-repeat top;
}

.footer-small {
  font-size: $alpha-copyright-font-size;
}

.copyright {
  a {
    &:not(.btn) {
      color: inherit;
      transition: color .3s ease;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .footer-small,
  .copyright {
    font-size: .875rem;
  }
}

.back-to-top-link {
  z-index: $zindex-fixed + 1;
}

.app-stores {
  display: flex;

  svg {
    width: 135px;
    height: 40px;
  }
}

.text-center {
  .app-stores {
    justify-content: center;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .app-stores#{$infix}-inline {
      .app-stores {
        flex-direction: row !important;
      }

      .app-store-item + .app-store-item {
        margin-top: 0 !important;
        margin-left: $spacer !important;
      }
    }

    .app-stores#{$infix}-stacked {
      .app-stores {
        flex-direction: column !important;
      }

      .app-store-item + .app-store-item {
        margin-top: $spacer !important;
        margin-left: 0 !important;
      }
    }
  }
}

a {
  text-decoration: none;

  &:not(.btn, .nav-link) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

$input-reverse-color: $input-bg !default;
$input-reverse-bg: $input-color !default;
$input-reverse-border-color: $input-bg !default;
$input-reverse-box-shadow: $input-box-shadow !default;

.reverse {
  .form-control {
    --input-color: #{$input-reverse-color};
    --input-bg: #{$input-reverse-bg};
    color: var(--input-color);
    background-color: var(--input-bg);
    border-color: $input-reverse-border-color;

    @each $state, $data in $form-validation-states {
      &.is-#{$state} {
        border-color: map-get($data, color);
      }
    }
  }
}

div.form-control {
  --input-color: #{$input-color};
  --input-bg: #{$input-bg};
  padding: $input-padding-x;
  border-radius: px2rem(15);

  &.loading {
    @include font-awesome-after($fa-var-spinner-third, $margin: map-get($spacers, 2));

    &::after {
      animation: 1.5s fa-spin infinite;
    }
  }

  .btn {
    border-radius: px2rem(15);
  }

  input.form-control {
    @extend .form-control-plaintext;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input {
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px var(--input-bg) inset !important;
      -webkit-text-fill-color: var(--input-color);
    }
  }
}

label {
  .required {
    color: theme-color("danger");
  }
}

address,
ol,
ul,
dl,
pre,
figure {
  margin-bottom: $paragraph-margin-bottom;
}

dd,
legend {
  margin-bottom: $paragraph-margin-bottom / 2;
}

p,
ol,
ul,
dl {
  &:last-child {
    margin-bottom: 0;
  }
}

.wine-color {
  @extend .badge;
  font-family: $font-family-sans-serif;
  font-size: calc(var(--wine-main-font-size) * .88);
  font-weight: 400;
}

.wine-metas {
  i {
    width: 1.25rem;
    text-align: center;
  }

  .location {
    span + span {
      &::before {
        margin-left: map-get($spacers, 1);
        content: "- ";
      }
    }
  }
}
